<template>
  <div
    class="container my-md-4 py-4 py-md-0 border-md-bottom"
    v-if="appIsLasAmericas"
  >
    <div class="row row-header">
      <div class="navbar-header">
        <Logo @toggleMenuMobile="showMobileMenu = !showMobileMenu" />
      </div>
    </div>
  </div>
  <section class="d-flex justify-content-md-end justify-content-between" v-else>
    <link-wrapper :location="'/'" class="d-block d-md-none d-lg-none">
      <img
        width="100"
        height="100"
        style="margin-left: 0rem"
        class="mx-3 mt-3"
        :src="$getFileSource('images/logos/logo.png')"
        :alt="$store.state.app.settings.websiteName"
      />
    </link-wrapper>
    <span class="sr-only"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <img
      class="w-100 d-none d-md-block d-lg-block"
      style="object-fit: contain"
      :src="$getFileSource('images/home/top.jpg')"
      :alt="$store.state.app.settings.websiteName"
    />
    <div class="icons d-md-none d-flex align-items-center p-4">
      <div class="mobile_only_icon_group account me-4">
        <link-wrapper
          location="/"
          data-modal="modal-log-in"
          class="modeltheme-trigger"
        >
          <font-awesome-icon class="text-orange" :icon="['fas', 'home']" />
        </link-wrapper>
      </div>

      <div class="mobile_only_icon_group cart">
        <link-wrapper :location="'/auctions'">
          <font-awesome-icon
            class="text-orange me-3"
            :icon="['fas', 'gavel']"
          />
        </link-wrapper>
      </div>
      <div class="mobile_only_icon_group search">
        <link-wrapper :location="'/direct-sale'">
          <font-awesome-icon
            class="text-orange me-3"
            :icon="['fas', 'store']"
          />
        </link-wrapper>
      </div>
      <div class="mobile_only_icon_group wishlist">
        <link-wrapper :location="'/contact'">
          <font-awesome-icon
            class="text-orange me-3"
            :icon="['fas', 'comment']"
          />
        </link-wrapper>
      </div>
      <div class="mobile_only_icon_group search">
        <a
          href="#"
          @click.prevent="showMobileMenu = !showMobileMenu"
          class="mt-search-icon bars-color fs-2"
        >
          <font-awesome-icon class="text-orange" :icon="['fas', 'bars']" />
        </a>
      </div>
    </div>
  </section>
  <navbar></navbar>
  <transition name="grow">
    <mobile-menu-bars v-if="showMobileMenu"></mobile-menu-bars>
  </transition>
</template>
<script>
import Logo from "../atoms/Logo/Logo.vue";
// import Cart from "../molecules/Cart/Cart.vue";
import Navbar from "../molecules/Navigation/Navigationbar.vue";
import MobileMenuBars from "../molecules/Mobile/MobileMenuBars.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";

export default {
  components: { Logo, Navbar, MobileMenuBars, LinkWrapper },
  computed: {
    appIsLasAmericas() {
      return this.$store.getters.appIsLasAmericas;
    },
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
};
</script>
<style scoped="scoped">
@media (max-width: 767.98px) {
  .menu-products,
  .search-form-product {
    display: none;
  }
  .row-header {
    display: flex;
  }
  .navbar-header {
    display: unset !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid rgba(221, 221, 221, 0.49);
  }
}
</style>

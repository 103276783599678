<template>
  <component
    :is="isLink ? 'LinkWrapper' : 'button'"
    :class="getWrappedClass"
    @click="$emit('onClick', text.toLowerCase().trim())"
    :location="location"
  >
    <slot>
      {{ text }}
    </slot>
  </component>
</template>

<script>
import ButtonFilled from "./ButtonFilled.vue";

export default {
  mixins: [ButtonFilled],
  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    getWrappedClass() {
      return {
        ...this.getClass,
        "btn bg-transparent btn-outline-light text-uppercase": true,
        primary: this.color == "primary",
        secondary: this.color == "secondary",
      };
    },
  },
};
</script>

<style scoped="scoped">
.primary:hover,
.secondary:hover {
  color: #fff;
  background-color: var(--primary-color) !important;
}
.primary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
}

.secondary {
  border: 1px solid white;
  color: white;
  font-weight: 600;
}
.normal {
  font-size: 14px;
  padding: 12px 25px !important;
}
.wide.normal {
  padding: 12px 45px !important;
}
.hasRoundedCorners.normal {
  border-radius: 30px !important;
}
.small {
  font-size: 12px;
  padding: 8px 15px !important;
}
.wide.small {
  padding: 8px 25px !important;
}
.hasRoundedCorners.small {
  border-radius: 30px !important;
}
.big {
  padding: 20px 40px !important;
  font-size: 15px;
}
.big.small {
  padding: 20px 60px !important;
}
.hasRoundedCorners.big {
  border-radius: 50px !important;
}
</style>

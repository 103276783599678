<template>
  <nav class="navbar navbar-expand-sm p-0">
    <div class="container justify-content-start">
      <div class="navbar w-100 p-0" :class="{ show: isActiveTablet }">
        <ul class="navbar-nav me-auto mb-2 ms-0 ms-lg-4 mb-lg-0 h-100 w-100">
          <template v-for="(link, i) in links">
            <li
              class="nav-item position-relative h-100 d-flex align-items-center"
              :key="link"
              v-if="type ? link.name !== 'FAQs' : true"
              @mouseover="hoveredItem = i"
              @mouseleave="hoveredItem = -1"
            >
              <LinkWrapper
                v-if="link.admin && check && role == 'admin'"
                class="nav-link text-white fs-6 mx-2"
                :location="link.location"
                :name="link.name"
                :external="link.external"
                :target="link.target"
              />

              <LinkWrapper
                v-else-if="link.check && check && link.user"
                class="nav-link text-white fs-6 mx-2"
                :location="link.location"
                :name="link.name"
                :external="link.external"
                :target="link.target"
              />

              <LinkWrapper
                v-else-if="!link.admin && !link.check"
                class="nav-link text-white fs-6 mx-2"
                :location="link.location"
                :name="link.name"
                :external="link.external"
                :target="link.target"
              />
              <template
                v-if="
                  link.component && link.componentProps !== undefined && check
                "
              >
                <transition name="showMenu">
                  <component
                    v-show="hoveredItem === i"
                    :is="link.component"
                    v-bind="link.componentProps"
                    class="menu-hover"
                  ></component>
                </transition>
              </template>
            </li>
          </template>
          <li
            class="
              nav-item
              position-relative
              h-100
              d-flex
              align-items-center
              ms-auto
            "
          >
            <div class="search-bar">
              <Search
                :placeholder="'Buscar Productos...'"
                :buttonType="'submit'"
                :selectDefault="'Categories'"
              />
            </div>
          </li>
          <li
            class="
              nav-item
              position-relative
              h-100
              d-flex
              align-items-center
              ms-auto
            "
            @mouseover="hoveredItem = i"
            @mouseleave="hoveredItem = -1"
            v-if="!check"
          >
            <a
              style="cursor: pointer"
              class="nav-link active text-white fs-6 px-0"
              :class="{ 'router-link-active': $route.name == 'MyAccount' }"
              @click="$router.push({ name: 'MyAccount' })"
            >
              Iniciar Sesión
            </a>
          </li>
          <li
            class="
              nav-item
              position-relative
              h-100
              d-flex
              align-items-center
              ms-auto
            "
            @mouseover="hoveredItem = i"
            @mouseleave="hoveredItem = -1"
            v-else
          >
            <a
              style="cursor: pointer"
              class="nav-link text-white fs-6 px-0"
              @click.prevent="logout"
            >
              Cerrar Sesión
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
// import NavigationSubMenu from "./NavigationSubMenu.vue";
import SubmenuVerticalA from "../../atoms/Menu/SubmenuVerticalA.vue";
import SubmenuVerticalB from "../../atoms/Menu/SubmenuVerticalB.vue";
import { links } from "../../../data/links.json";
import LinkWrapper from "../../atoms/LinkWrapper/LinkWrapper.vue";
import Search from "@/components/molecules/Search/Search.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    Search,
    SubmenuVerticalA,
    SubmenuVerticalB,
    LinkWrapper,
  },
  data() {
    return {
      isActive: false,
      links,
      hoveredItem: -1,
      type: process.env.VUE_APP_NAME == "casalira" ? true : false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push({ name: "MyAccount" });
    },
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      role: "auth/role",
    }),
  },
};
</script>
<style>
.grow-enter-active,
.grow-leave-active {
  transition: max-height 0.5s ease;
  max-height: 400px;
  overflow: hidden;
}

.grow-enter-from,
.grow-leave-to {
  max-height: 0px;
}

.showMenu-enter-active,
.showMenu-leave-active {
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.showMenu-enter-from,
.showMenu-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
<style scoped="scoped">
.navbar {
  background-color: var(--primary-color);
  height: 70px;
}
.text-uppercase {
  cursor: pointer;
}
.nav-item a {
  color: #ffff;
  font-size: 14px !important;
  font-weight: 500 !important;
}
/* .nav-item a:active{
  text-decoration: overline;
}
.nav-item a:hover{
  text-decoration:overline;
  animation: ease-in;
  transition: 2s;
} */
li > a:hover {
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.05s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
/*Edit starts here*/
li > a::after {
  content: "";
  top: 15px;
  margin: auto;
  left: 0;
  right: 0;
  width: 30%;
  position: absolute;
  height: 2px;
  background: #ffff;
  transition: cubic-bezier(0.77, 0, 0.18, 1) 0.3s;
  transform: scale(0);
}
li > a:hover::after,
li > a.router-link-active::after {
  transform: scale(1);
}
.bot_nav_cat {
  box-shadow: 0 3px 25px rgb(0 0 0 / 10%);
}
li {
  transition: cubic-bezier(0.77, 0, 0.18, 1) 1s;
}
.collapse.navbar-collapse {
  height: 70px;
}

.nav-item > .menu-hover {
  top: 100%;
  left: 0;
}
.cat-icon {
  margin-top: 3px;
}
.sign-in span {
  color: #ffff;
  font-size: 14px !important;
  font-weight: 700 !important;
}
.dropdown {
  height: 70px;
}
.dropdown-toggle::after {
  content: none;
}
.navbar a {
  text-align: left;
  box-sizing: border-box;
  text-decoration: none;
  background-color: transparent;
  color: #606060;
  font-weight: 400;
  font-size: 14px;
}
.nav-link.bot_nav_cat {
  background: #fff !important;
  opacity: 1;
  color: #484848;
  display: inline-block;
  outline: none;
  padding: 10px 36px;
  font-size: 13px;
}

.menu {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 200px;
}

.menu > li > a {
  background: #000;
  color: #fff;
  display: block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
}

.menu ul {
  background: #ddd;
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  top: 35px;
  width: 100%;
}
.menu ul a {
  color: #000;
  display: block;
  padding: 5px 20px;
}
/* Animation */
.navbar li ul {
  position: absolute;
  left: 0;
  top: 36px;
  z-index: 1;
}
.navbar li ul li {
  overflow: hidden;
  height: 0;
  -webkit-transition: height 200ms ease-in;
  -moz-transition: height 200ms ease-in;
  -o-transition: height 200ms ease-in;
  transition: height 200ms ease-in;
}
.navbar ul > li:hover ul li {
  height: 36px;
}
/*  */
@media (max-width: 767.98px) {
  .navbar {
    display: none !important;
  }
}

.search-bar {
  min-width: 500px;
}

@media (max-width: 1000.98px) {
  .search-bar {
    min-width: 200px;
    display: none;
  }
}
</style>

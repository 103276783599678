<template>
  <div>
    <span class="sr-only"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <div class="logo-section align-items-center justify-content-between">
      <div class="logo logo-image d-block text-center">
        <div class="d-flex align-items-center justify-content-center">
          <h2
            class="mas-title text-uppercase d-none d-md-block d-lg-block mx-2"
            style="color: #00002e"
          >
            Remates
          </h2>
          <link-wrapper :location="'/'" class="d-none d-md-block d-lg-block">
            <img
              style="margin-left: 0.5rem"
              class="mx-5"
              :src="$getFileSource(path)"
              :alt="$store.state.app.settings.websiteName"
            />
          </link-wrapper>
          <link-wrapper :location="'/'" class="d-block d-md-none d-lg-none">
            <img
              style="margin-left: 0.5rem"
              class="mx-5"
              :src="$getFileSource(pathMobile)"
              :alt="$store.state.app.settings.websiteName"
            />
          </link-wrapper>
          <h2
            class="
              mas-title
              text-uppercase
              d-none d-md-block d-lg-block
              text-blue
              ml-2
            "
            style="color: #014ba0"
          >
            Las Americas
          </h2>
        </div>
      </div>
      <div class="icons d-md-none d-flex align-items-center">
        <div class="mobile_only_icon_group account me-2">
          <link-wrapper
            location="/"
            data-modal="modal-log-in"
            class="modeltheme-trigger"
          >
            <font-awesome-icon class="text-orange" :icon="['fas', 'home']" />
          </link-wrapper>
        </div>

        <div class="mobile_only_icon_group cart">
          <link-wrapper :location="'/auctions'">
            <font-awesome-icon
              class="text-orange me-2"
              :icon="['fas', 'gavel']"
            />
          </link-wrapper>
        </div>
        <div class="mobile_only_icon_group search">
          <link-wrapper :location="'/direct-sale'">
            <font-awesome-icon
              class="text-orange me-2"
              :icon="['fas', 'store']"
            />
          </link-wrapper>
        </div>
        <div class="mobile_only_icon_group wishlist">
          <link-wrapper :location="'/contact'">
            <font-awesome-icon
              class="text-orange me-2"
              :icon="['fas', 'comment']"
            />
          </link-wrapper>
        </div>
        <div class="mobile_only_icon_group search">
          <a
            href="#"
            @click.prevent="$emit('toggleMenuMobile')"
            class="mt-search-icon bars-color fs-4"
          >
            <font-awesome-icon class="text-orange" :icon="['fas', 'bars']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkWrapper from "../../atoms/LinkWrapper/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  data() {
    return {
      showMobileMenu: false,
      path:
        process.env.VUE_APP_NAME == "casalira"
          ? "images/logos/logo.png"
          : "images/logos/rla.png",
      pathMobile:
        process.env.VUE_APP_NAME == "casalira"
          ? "images/logos/logo.png"
          : "images/logos/rla-mobile.png",
    };
  },
  computed: {
    webIsCasalira() {
      return process.env.VUE_APP_NAME == "casalira";
    },
  },
};
</script>
<style>
.text-red {
  color: #fe0000;
}
.text-blue {
  color: #0a51a1;
}
.text-brown {
  color: #cbaa5c;
}
.mas-title {
  font-size: 50px !important;
  font-weight: 800 !important;
}

@media (max-width: 767.98px) {
  .logo-section {
    display: flex;
  }
}
.bars-color {
  color: var(--primary-color);
}

.text-orange {
  color: var(--primary-color);
}
</style>
